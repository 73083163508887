import React from "react";
import Logo from "../Assets/Carren.jpeg";
import { SiLinkedin } from "react-icons/si";
import TwitterIcon from '@mui/icons-material/X';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography fontWeight={545} color="#4c4c4c" {...props}>
      {'Copyright © '}
        Carren Technologies LTD.
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = () => {
  const navigate = useNavigate();

  const handlePricingClick = () => {
    navigate("/pricing");
  };

  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="Carren Logo" style={{ width: "480px", height: "auto" }} />
        </div>
        <div className="footer-icons">
          <a href="https://x.com/CarrenTechLtd" target="_blank" rel="noopener noreferrer">
            <TwitterIcon />
          </a>
          <SiLinkedin />
          <a href="mailto:info@carren.cc">
            <MailOutlineIcon />
          </a>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span>API Docs</span>
          <span>Developers</span>
          <span>Integrations</span>
          <span>Features</span>
          <a href="mailto:info@carren.cc" style={{ color: "inherit", textDecoration: "none" }}>
            <span>Support</span>
          </a>
          <span onClick={handlePricingClick} style={{ cursor: "pointer", color: "blue" }}>Pricing</span>
        </div>
        <div className="footer-section-columns">
          <span>Contact Us</span>
          <a href="mailto:info@carren.cc" style={{ color: "blue", textDecoration: "none" }}>
            info@carren.cc
          </a>
          <span>Carren.</span>
          <span style={{ color: " #161C24" }}>
            <Copyright />
          </span>
        </div>
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
