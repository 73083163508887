import React from "react";
import Typography from "@mui/material/Typography";

const Contact = () => {
  return (
    <div className="contact-page-wrapper">
      <Typography 
        variant="h4" 
        className="primary-heading" 
        fontWeight="bold" 
        style={{ color: "#161C24" }}
      >
        Have any Questions in Mind?<br />
        Let Us Help You  
      </Typography>
      <div className="contact-form-container">
        <input type="text" placeholder="yourmail@gmail.com" />
        <button className="secondary-button">Submit</button>
      </div>
      <br/>
      <hr/>
    </div>

  );
};

export default Contact;
