import React from "react";
import UnbankedImg from "../Assets/unbanked.png";
import IntegratedSysImage from "../Assets/Integrated System.png";
import AnalyticsImage from "../Assets/analytics.png";
import ControlFlowImage from "../Assets/flowchart.jpeg";
import Typography from "@mui/material/Typography";

const Work = () => {
  const workInfoData = [
    {
      image: UnbankedImg,
      title: "Built for the Unbanked",
      text: "Carren is built on alternative financial data such as Mobile Money that accounts for upto 90% of transactions.",
    },
    {
      image: IntegratedSysImage,
      title: "Integrated System",
      text: "Applicant Onboarding, Underwriting, Financial Modelling and Credit Scoring - is all in one place offering easier management and reporting.",
    },
    {
      image: AnalyticsImage,
      title: "Analytics",
      text: "No need to make judgement based on guesses, our software provides curated insights on transactional data to guide credit decisions.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <Typography 
            variant="h4" 
            className="primary-heading" 
            fontWeight="bold" 
            style={{ color: "#161C24" }}
          >
            The Fastest Path From<br />
            Onboarding to Lending.
        </Typography>
        <br/>
        <img src={ControlFlowImage} alt="" style={{ width: "100%" }} />
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
