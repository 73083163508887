// SignInLayout.js
import React from "react";
import SignIn from "../Layouts/authentication/sign-in";

const SignInLayout = () => {
  return (
    <div className="SignInLayout">
      <SignIn />
    </div>
  );
};

export default SignInLayout;
