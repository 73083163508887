// SignInLayout.js
import React from "react";
import SignIn from "./sign-in";

const AvanzarSignInLayout = () => {
  return (
    <div className="SignInLayout">
      <SignIn />
    </div>
  );
};

export default AvanzarSignInLayout;
