
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Pricing from "./pricing";

const PricingLayout = () => {
  return (
    <div className="App">
      <Navbar />
      <Pricing />
      <Footer />
    </div>
  );
};

export default PricingLayout;
