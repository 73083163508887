import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const AdminRoute = ({ element: Component }) => {
  const accessToken = Cookies.get('accessToken');
  const userRole = Cookies.get('role'); // Assuming 'role' is stored in cookies during login

  // Check if user is logged in and has the admin role
  if (!accessToken) {
    return <Navigate to="/avanzar-signin" />; // Redirect to login if not logged in
  }

  if (userRole !== 'admin') {
    return <h3>403 Forbidden: Admins Only</h3>; // Show forbidden if not admin
  }

  return <Component />;
};

export default AdminRoute;
