import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/Carren.jpeg";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import PriceCheckRoundedIcon from "@mui/icons-material/PriceCheckRounded";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArticleIcon from "@mui/icons-material/Article";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const Navbar = ({ scrollToSection }) => {
  const [selectedLink, setSelectedLink] = useState(""); // State to track selected link
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const handleLinkHover = (text) => {
    setSelectedLink(text);
  };

  const handleLinkLeave = () => {
    setSelectedLink("");
  };

  const handleButtonHover = () => {
    setIsButtonHovered(!isButtonHovered);
  };

  const handleDrawerClose = () => {
    setOpenMenu(false);
  };

  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
      path: "/"
    },
    {
      text: "Learn More",
      icon: <FindInPageIcon />,
      path: "/learn more" 
    },
    {
      text: "FAQ",
      icon: <ArticleIcon />,
      path: "/faq" 
    },
    {
      text: "Pricing",
      icon: <PriceCheckRoundedIcon />,
      path: "/pricing"
    },
    {
      text: "Register",
      icon: <PersonAddIcon />,
      path: "/signup"
    },
  ];

  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <nav>
        <div className="nav-logo-container">
          <img src={Logo} alt="Carren Logo" style={{ width: "280px", height: "auto" }} />
        </div>
        <div className="navbar-links-container">
          {menuOptions.map((item) => (
            <Typography
              key={item.text}
              component={Link}
              to={item.path}
              className={`navbar-link ${selectedLink === item.text ? "selected" : ""}`}
              onMouseEnter={() => handleLinkHover(item.text)}
              onMouseLeave={handleLinkLeave}
              variant="body3"
              style={{
                fontWeight: "545",
                textDecoration: "none",
                position: "relative",
                borderBottom: selectedLink === item.text ? "2px solid #000" : "none", // Underline effect
              }}
            >
              {item.text}
            </Typography>
          ))}
              <Button
                component={Link}
                to="/signin"
                variant="contained"
                size="small" 
                style={{
                  backgroundColor: isButtonHovered ? "#333" : "#1C252E",
                  color: "#ffffff",
                  textTransform: 'none', 
                  transition: "background-color 0.3s ease",
                  textDecoration: "none",
                  padding: '6px 12px', 
                  fontSize: '0.875rem'
                }}
                onMouseEnter={handleButtonHover}
                onMouseLeave={handleButtonHover}
              >
                Sign In
              </Button>
        </div>
        <div className="navbar-menu-container">
          <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
        </div>
        <Drawer open={openMenu} onClose={handleDrawerClose} anchor="right">
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleDrawerClose}
            onKeyDown={handleDrawerClose}
          >
            <List>
              {menuOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton component={Link} to={`/${item.text.toLowerCase()}`}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
        </Drawer>
      </nav>
    </div>
  );
};

export default Navbar;