// App.js
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainLayout from "./Components/MainLayout";
import SignInLayout from "./Components/SignInLayout";
import SignUp from "./Layouts/authentication/Sign-up";
import ForgotPassword from "./Layouts/authentication/forgot-password";
import LoginForm from "./Layouts/authentication/sign-in";
import ResetPasswordForm from "./Layouts/authentication/reset-password";
import LearnMore from "./Components/LearnMore";
import FAQLayout from "./Components/FAQLayout";
import Highlights from "./Components/highlights";
import PricingLayout from "./Components/PricingLayout";
import BookDemo from "./Components/BookNow";
import TeamManagementLayout from "./Components/TeamManagement/TeamMgmtLayouts";
import AdminRoute from './Components/TeamManagement/AdminRoute';
import AvanzarSignInLayout from './Components/TeamManagement/AvanzarSignInLayout';


function App() {
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<MainLayout scrollToSection={scrollToSection} />}
        />
        <Route path="/signin" element={<SignInLayout />} />
        <Route path="/avanzar-signin" element={<AvanzarSignInLayout />} />
        <Route 
        path="/avanzar-team-management" 
        element={<AdminRoute element={TeamManagementLayout} />} 
      />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/reset-password" element={<ResetPasswordForm />} />
        <Route path="/pricing" element={<PricingLayout />} />
        <Route path="/faq" element={<FAQLayout />} />
        <Route path="/home" element={<MainLayout />} />
        <Route path="/explore carren" element={<Highlights />} />
        <Route path="/learn more" element={<LearnMore />} />
        <Route path="/book-now" element={<BookDemo />} />
        <Route path="/home" element={<MainLayout />} />

      </Routes>
    </Router>
  );
}

export default App;

