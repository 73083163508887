import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = (theme) => ({
  homeContainer: {
    paddingTop: '22px',
    backgroundColor: "#f2f0f0",
    minHeight: '100vh',
  },
  homeBannerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 20px 10px',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },  
  homeTextSection: {
    marginLeft: '50px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    backgroundColor: '#ffffff',
    padding: '25px',
    width: '100%',
    borderRadius: '20px',
    marginTop: '25px',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      padding: '30px',
    },
  },
  inputForm: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    borderRadius: '10px',
    height: '50px',
    backgroundColor: '#fafafa',
    border: '1.5px solid #ecedec',
    transition: 'border-color 0.3s ease',
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  input: {
    marginLeft: '10px',
    flex: 1,
    height: '100%',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSubmit: {
    backgroundColor: '#212B36',
    color: 'white',
    fontSize: '15px',
    fontWeight: '500',
    borderRadius: '10px',
    height: '50px',
    width: '100%',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#161C24',
    },
  },
  flexColumnLabel: {
    color: '#151717',
    fontWeight: '600',
    flexBasis: '48%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
  flexRow: {
    display: 'flex',
    gap: '15px',
    justifyContent: 'space-between',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  span: {
    fontSize: '14px',
    color: theme.palette.primary.main,
    fontWeight: '500',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  p: {
    textAlign: 'center',
    color: 'black',
    fontSize: '14px',
    margin: '5px 0',
    '& span:hover': {
      textDecoration: 'underline',
    },
  },
  visibilityIcon: {
    cursor: 'pointer',
    marginLeft: '5px',
    color: '#444444',
  },
  extendedBackground: {
    backgroundColor: "#f2f0f0",
    flexGrow: 1,
  },
});

const SignUpForm = ({ classes }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required!"),
    lastName: Yup.string().required("Last Name is required!"),
    email: Yup.string().email("Invalid email").required("Email is required!"),
    company: Yup.string().required("Company is required!"),
    password: Yup.string()
      .required('Password is required!')
      .min(6, 'Password must be at least 6 characters!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match!')
      .required('Confirm Password is required!'),
    termsAndConditions: Yup.boolean()
      .oneOf([true], 'You must accept the terms and conditions to register!')
  });

  useEffect(() => {
    let timeoutId;
    if (openSnackbar) {
      timeoutId = setTimeout(() => {
        setOpenSnackbar(false);
        if (snackbarSeverity === 'success') {
          navigate("/signin"); // Redirect to login page for success message
        }
      }, 4000); // Adjust the timeout duration as needed
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [openSnackbar, navigate, snackbarSeverity]);

  return (
    <>

   
    <div className={classes.extendedBackground}>
      <div className={classes.homeContainer}>
        <div className={classes.homeBannerContainer}>
          <div className={classes.homeTextSection}>
            <Typography variant="h4" className="primary-heading" fontWeight="bold" style={{ color: "#161C24" }}>
              Carren.
            </Typography>
            <Typography mt={12.5} variant="h4" className="primary-heading" fontWeight="545" style={{ color: "#161C24" }}>
              Credit Scoring Infrastructure <br />
              for <br />
              Micro Businesses <br />
              in Africa.
            </Typography>
            <Typography mt={4.5} variant="body1" className="primary-text" style={{ color: "#444444" }}>
              Create Your Account &#x1F44B;
            </Typography>
            <br />
          </div>
          <div className="home-image-section">
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              company: '',
              password: '',
              confirmPassword: '',
              termsAndConditions: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              const formData = new FormData();
              formData.append('firstName', values.firstName);
              formData.append('lastName', values.lastName);
              formData.append('email', values.email);
              formData.append('company', values.company);
              formData.append('password', values.password);
              formData.append('termsAndConditions', false);
            
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/create_user`, formData)
                .then(response => {
                  console.log(response.data);
                  setOpenSnackbar(true); // Display Snackbar
                  resetForm(); // Reset form fields
                })
                .catch(error => {
                  console.error(error);
                  let errorMessage = 'Error Creating User. Please Contact Us!';
                  let severity = 'error'; // Default severity level for errors
                  if (error.response && error.response.data && error.response.data.error) {
                    errorMessage = error.response.data.error;
                    if (errorMessage === 'Email already in use.') {
                      severity = 'warning'; // Set severity level to warning for specific error
                    }
                  }
                  setSnackbarSeverity(severity); // Set severity level
                  setSnackbarMessage(errorMessage); // Set snackbar message
                  setOpenSnackbar(true); // Display Snackbar
                  if (severity !== 'warning') {
                    resetForm(); // Reset form fields only if it's not a warning
                  }
                });
            }}
          >
              {({ handleSubmit, errors, touched, values, handleChange }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.flexRow}>
                  <div className={classes.flexColumnLabel}>
                    <label htmlFor="firstName">First Name</label>
                    <div className={classes.inputForm}>
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="Enter your First Name"
                        className={classes.input}
                      />
                    </div>
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      style={{ color: '#f44336', fontSize: '12px', fontWeight: 'lighter' }}
                    />
                  </div>
                  <div className={classes.flexColumnLabel}>
                    <label htmlFor="lastName">Last Name</label>
                    <div className={classes.inputForm}>
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Enter your Last Name"
                        className={classes.input}
                      />
                    </div>
                    {errors.lastName && touched.lastName && (
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        style={{ color: '#f44336', fontSize: '12px', fontWeight: 'lighter' }}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.flexColumnLabel}>
                  <label htmlFor="email">Email</label>
                </div>
                <div className={classes.inputForm}>
                  <Field
                    type="text"
                    name="email"
                    placeholder="Enter your Email"
                    className={classes.input}
                  />
                </div>
                {errors.email && touched.email && (
                  <ErrorMessage
                    name="email"
                    component="div"
                    style={{ color: '#f44336', fontSize: '12px', fontWeight: 'lighter' }}
                  />
                )}
                <div className={classes.flexColumnLabel}>
                  <label htmlFor="company">Company</label>
                </div>
                <div className={classes.inputForm}>
                  <Field
                    type="text"
                    name="company"
                    placeholder="Enter your Company name"
                    className={classes.input}
                  />
                </div>
                {errors.company && touched.company && (
                  <ErrorMessage
                    name="company"
                    component="div"
                    style={{ color: '#f44336', fontSize: '12px', fontWeight: 'lighter' }}
                  />
                )}
                <div className={classes.flexColumnLabel}>
                  <label htmlFor="password">Password</label>
                </div>
                <div className={classes.inputForm}>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter your Password"
                    className={classes.input}
                  />
                  {showPassword ? (
                    <VisibilityOffIcon
                      className={classes.visibilityIcon}
                      onClick={handleTogglePassword}
                    />
                  ) : (
                    <VisibilityIcon
                      className={classes.visibilityIcon}
                      onClick={handleTogglePassword}
                    />
                  )}
                </div>
                {errors.password && touched.password && (
                  <ErrorMessage
                    name="password"
                    component="div"
                    style={{ color: '#f44336', fontSize: '12px', fontWeight: 'lighter' }}
                  />
                )}
                <div className={classes.flexColumnLabel}>
                  <label htmlFor="confirmPassword">Confirm Password</label>
                </div>
                <div className={classes.inputForm}>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm your Password"
                    className={classes.input}
                  />
                  {showPassword ? (
                    <VisibilityOffIcon
                      className={classes.visibilityIcon}
                      onClick={handleTogglePassword}
                    />
                  ) : (
                    <VisibilityIcon
                      className={classes.visibilityIcon}
                      onClick={handleTogglePassword}
                    />
                  )}
                </div>
                {errors.confirmPassword && touched.confirmPassword && (
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    style={{ color: '#f44336', fontSize: '12px', fontWeight: 'lighter' }}
                  />
                )}
              <div className={`${classes.flexRow} ${classes.checkboxContainer}`}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    color="primary"
                    name="termsAndConditions"
                    onChange={handleChange}
                    checked={values.termsAndConditions}
                  />
                  <Typography variant="body2" style={{ color: "#444444", margin: 0 }}>
                    I accept the <span className={classes.span}>Terms and Conditions</span>
                  </Typography>
                </div>
                {errors.termsAndConditions && touched.termsAndConditions && (
                  <ErrorMessage
                    name="termsAndConditions"
                    component="div"
                    style={{ color: '#f44336', fontSize: '12px', fontWeight: 'lighter' }}
                  />
                )}
              </div>
              <button type="submit" className={classes.buttonSubmit}>Register</button>
              <p className={classes.p}>
                Already have an account? 
                <Link to="/signin" className={classes.span}>
                  Sign In
                </Link>
              </p>
            </form>
            )}
          </Formik>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        {snackbarSeverity === 'error' ? (
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        ) : snackbarSeverity === 'warning' ? (
          <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        ) : (
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Successful Registration. Redirecting to Login..
          </Alert>
        )}
      </Snackbar>
    </div>
    </>
  );
};

export default withStyles(styles)(SignUpForm);
