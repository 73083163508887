import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'; // Import js-cookie

const styles = (theme) => ({
  homeContainer: {
    paddingTop: '50px',
    backgroundColor: "#f2f0f0",
    minHeight: '100vh', // Ensure the container takes up the full viewport height
  },
  homeBannerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 20px',
    flexDirection: 'column', // Stack content vertically
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row', // For larger screens, revert back to row layout
    },
  },
  homeTextSection: {
    alignSelf: 'flex-start',
    marginTop: '1px',
    marginLeft: '80px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px', // Adjust margin for smaller screens
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    backgroundColor: '#ffffff',
    padding: '20px',          // Adjust padding for small screens
    width: '100%',            // Full width on small screens
    borderRadius: '20px',
    fontFamily: theme.typography.fontFamily,
    marginTop: '30px',
    marginBottom: '20px',
    paddingBottom: '10px',    // Adjust padding for small screens
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      padding: '30px',
      paddingBottom: '30px',
    },
  },
  inputForm: {
    border: '1.5px solid #ecedec',
    borderRadius: '10px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    transition: '0.2s ease-in-out',
    '&:focus-within': {
      border: `1.5px solid ${theme.palette.primary.main}`,
    },
  },
  input: {
    marginLeft: '10px',
    borderRadius: '10px',
    border: 'none',
    width: '100%',
    height: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  buttonSubmit: {
    margin: '20px 0 10px 0',
    backgroundColor: '#212B36',
    border: 'none',
    color: 'white',
    fontSize: '15px',
    fontWeight: '500',
    borderRadius: '10px',
    height: '50px',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#161C24',
    },
  },
  flexColumnLabel: {
    color: '#151717',
    fontWeight: '600',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
  },
  span: {
    fontSize: '14px',
    marginLeft: '5px',
    color: theme.palette.primary.main,
    fontWeight: '500',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  p: {
    textAlign: 'center',
    color: 'black',
    fontSize: '14px',
    margin: '5px 0',
    '& span:hover': {
      textDecoration: 'underline',
    },
  },
  visibilityIcon: {
    cursor: 'pointer',
    marginLeft: '5px',
    color: '#444444',
  },
  extendedBackground: {
    backgroundColor: "#f2f0f0",
    flexGrow: 1, // Fill remaining space
  },
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://carren.cc/">
        Carren Technologies LTD.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const LoginForm = ({ classes }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        // Store JWT tokens in cookies
        Cookies.set('accessToken', data.access_token, { domain: 'carren.cc' });
        Cookies.set('refreshToken', data.refresh_token, { domain: 'carren.cc' });
        Cookies.set('role', data.role, { domain: 'carren.cc' });
        console.log("Tokens stored:", data.access_token, data.refresh_token);
        // Redirect to dashboard without token in the URL
        window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}`;
      } else {
        const data = await response.json();
        setError(data.error);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error logging in. Please try again.");
    }
  };

  return (
    <div className={classes.extendedBackground}>
      <div className={classes.homeContainer}>
        <div className={classes.homeBannerContainer}>
          <div className={classes.homeTextSection}>
            <Typography variant="h4" className="primary-heading" fontWeight="bold" style={{ color: "#161C24" }}>
              Carren.
            </Typography>
            <Typography variant="h4" mt={16.5} className="primary-heading" fontWeight="600" style={{ color: "#161C24" }}>
              Welcome back,
            </Typography>
            <Typography mt={2} variant="body1" className="primary-text" style={{ color: "#444444" }}>
              Your Customers are waiting ...
            </Typography>
            <br />
          </div>
          <div className="home-image-section">
            <form className={classes.form} onSubmit={handleSignIn}>
              <div className={classes.flexColumnLabel}>
                <label>Email </label>
              </div>
              <div className={classes.inputForm}>
                <input
                  type="text"
                  className={classes.input}
                  placeholder="Enter your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className={classes.flexColumnLabel}>
                <label>Password </label>
              </div>
              <div className={classes.inputForm}>
                <input
                  type={showPassword ? "text" : "password"}
                  className={classes.input}
                  placeholder="Enter your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {showPassword ? (
                  <VisibilityOffIcon
                    className={classes.visibilityIcon}
                    onClick={handleTogglePassword}
                  />
                ) : (
                  <VisibilityIcon
                    className={classes.visibilityIcon}
                    onClick={handleTogglePassword}
                  />
                )}
              </div>

              {error && <div style={{ color: "red" }}>{error}</div>}

              <div className={classes.flexRow}>
                <div>
                  <Checkbox color="primary"/>
                  <label className={classes.flexColumnLabel}>Remember me </label>
                </div>
                <Link to="/forgot-password" className={classes.span}>
                  Forgot password?
                </Link>
              </div>
              <button className={classes.buttonSubmit}>Sign In</button>
              <p className={classes.p}>
                Don't have an account? 
                <Link to="/signup" className={classes.span}>
                  Sign Up
                </Link>
              </p>
              <Copyright sx={{ mt: 2, mb: 0 }} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(LoginForm);
