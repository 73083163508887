import React from "react";
import BannerImage from "../Assets/home-carren.jpg";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const Home = () => {
  return (
    <div className="home-container" style={{ backgroundColor: "#ffffff" }}>
      <Navbar />

      <div className="home-banner-container">
        {/* Text Section */}
        <div className="home-text-section">
          <Typography 
            variant="h3" 
            className="primary-heading" 
            fontWeight="bold" 
            style={{ color: "#161C24" }}
          >
            Credit Solution <br />
            built for <br />
            the Underserved.
          </Typography>

          <Typography 
            variant="body1" 
            className="primary-text" 
            style={{ color: "black", marginTop: '16px' }}
          >
            Alternative lending solutions for the banked and the unbanked.
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '16px' }}>
            {/* <Link to="/signup" className="secondary-button">
              Start Free Trial
            </Link> */}
            <Button
              component={Link}
              to="/signup"
              variant="contained"
              style={{ textTransform: 'none', backgroundColor: '#118D57', color: '#ffffff' }}
            >
              Start Free Trial
            </Button>

            <Button
              component={Link}
              to="/book-now"
              variant="outlined"
              style={{ textTransform: 'none', borderColor: '#1C252E', color: '#1C252E' }}
              endIcon={<FiArrowRight />}
            >
              Book A Demo
            </Button>
          </div>
        </div>

        {/* Image Section */}
        <div className="home-image-section">
          <img 
            src={BannerImage} 
            alt="Banner" 
            style={{ width: "700px", height: "auto" }} 
          />
        </div>
      </div>
    </div>
  );
};

export default Home;