
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import TeamMembers from "./TeamMembers";
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"; 
import "./teammanagement.css";

const TeamManagement = () => {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [newUser, setNewUser] = useState({ name: "", role: "", email: "", phone: "" }); // Added email & phone
  const [roles, setRoles] = useState({ teamLeader: false, collectionOfficer: false });
  const [newTeam, setNewTeam] = useState("");
  const [openDialog, setOpenDialog] = useState(false); // State for dialog

  let accessToken = Cookies.get('accessToken');

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/teams`,
        {
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );
      const teamsData = response.data;
      setTeams(Array.isArray(teamsData) ? teamsData : [teamsData]);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const handleTeamClick = (teamId) => {
    setSelectedTeam(teamId);
    fetchTeamMembers(teamId);
    setOpenDialog(true); // Open dialog on team click
  };

  const fetchTeamMembers = async (teamId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/teams/${teamId}/members`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setTeamMembers(response.data);
      updateRoleAvailability(response.data);
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };

  const updateRoleAvailability = (members) => {
    const teamLeaderExists = members.some((member) => member.role === "Team Leader");
    const collectionOfficerExists = members.some((member) => member.role === "Collection Officer");

    setRoles({
      teamLeader: teamLeaderExists,
      collectionOfficer: collectionOfficerExists,
    });
  };

  const handleUserChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/teams/${selectedTeam}/add-member`, newUser, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      fetchTeamMembers(selectedTeam);
      setNewUser({ name: "", role: "", email: "", phone: "" }); // Reset after submit
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleTeamCreation = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/teams`, { name: newTeam }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      setNewTeam("");
      fetchTeams();
    } catch (error) {
      console.error("Error creating team:", error);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
    setSelectedTeam(""); // Clear selected team
  };

  return (
    <div className="team-management-container">
      <h2>Team Management</h2>
      
      <div className="team-block">
        <div className="form-block">
          <h4>Create a Team</h4>
          <form onSubmit={handleTeamCreation}>
            <input
              type="text"
              name="team"
              placeholder="Enter team name"
              value={newTeam}
              onChange={(e) => setNewTeam(e.target.value)}
              required
            />
            <button type="submit">Create Team</button>
          </form>
        </div>
      </div>

      <div className="teams-container">
        <h4>Your Teams</h4>
        <div className="team-grid">
          {teams.map((team) => (
            <div 
              key={team.id} 
              className="team-card" 
              onClick={() => handleTeamClick(team.id)}
            >
              <h5>{team.name}</h5>
            </div>
          ))}
        </div>
      </div>

      {/* Dialog for adding members */}
      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>Manage Team Members for </DialogTitle>
        <DialogContent>
          <div className="members-container">
            <h4>Add User to Team </h4>
            <form onSubmit={handleUserSubmit}>
              <input
                type="text"
                name="name"
                placeholder="User Name"
                value={newUser.name}
                onChange={handleUserChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="User Email"
                value={newUser.email}
                onChange={handleUserChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={newUser.phone}
                onChange={handleUserChange}
                required
              />
              <select
                name="role"
                value={newUser.role}
                onChange={handleUserChange}
                required
              >
                <option value="">Select Role</option>
                <option value="Sales Agent">Sales Agent</option>
                <option value="Team Leader" disabled={roles.teamLeader}>
                  Team Leader
                </option>
                <option value="Collection Officer" disabled={roles.collectionOfficer}>
                  Collection Officer
                </option>
              </select>
              <button type="submit">Add User</button>
            </form>

            <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '3px' }}>
              Team Members
            </Typography>
            <TeamMembers members={teamMembers} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TeamManagement;