
import React from "react";
import Navbar from "./Navbar";
// import About from "./About";
import Footer from "./Footer";
import Features from "./Steps";

const LearnMore = () => {
  return (
    <div className="App">
      <Navbar />
      <Features />
      {/* <About /> */}
      <Footer />
    </div>
  );
};

export default LearnMore;
