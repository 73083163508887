// TeamMembers.js
import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const TeamMembers = ({ members }) => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "role", headerName: "Role", width: 150 },
  ];

  return (
    <div style={{ height: 320, width: "80%" }}>
      <DataGrid
        rows={members}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        rowHeight={35}
      />
    </div>
  );
};

export default TeamMembers;
