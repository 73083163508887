import React from "react";
import  InsightImg from "../Assets/insights.jpeg";
import Typography from "@mui/material/Typography";

const TransformDecisions = () => {
  return (
    <div className="home-container" style={{ backgroundColor: "#ffffff" }}>
      <div className="home-banner-container">
        <div className="home-text-section">
          <Typography variant="h3" className="primary-heading" fontWeight="bold" style={{ color: "black" }}>
            Transform Data
          </Typography>
          <Typography variant="h3" className="primary-heading" fontWeight="bold" style={{ color: "black" }}>
            into Decisions.
          </Typography>
          <br/>
          <Typography variant="body1" className="primary-text" fontWeight="bold" style={{ color: "black" }}>
            Use our accurate and up-to-date credit scores to make informed lending decisions for potential borrowers.
          </Typography>
          <br/>
          <Typography variant="body1" className="primary-text" style={{ color: "black" }}>
            Instantly Generate Credit Scores by importing Mobile Money PDF Documents containing Financial Data.
          </Typography>
          <br/>
        </div>
        <div className="home-image-section">
          <img src={InsightImg} alt="" style={{ width: "700px", height: "auto" }} />
        </div>
      </div>
    </div>
  );
};

export default TransformDecisions;
