import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './BookDemo.css';

const BookDemo = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const navigate = useNavigate();

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    company: Yup.string().required('Company is required'),
    note: Yup.string(),
    selectedDate: Yup.date().nullable().required('Date is required'),
    selectedTime: Yup.date().nullable().required('Time is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const bookedTime = values.selectedDate && values.selectedTime
        ? new Date(values.selectedDate).setHours(values.selectedTime.getHours(), values.selectedTime.getMinutes())
        : '';
      
      const response = await axios.post('http://127.0.0.1:5000/book-demo', {
        full_name: values.fullName,
        email: values.email,
        phone_number: values.phoneNumber,
        company: values.company,
        note: values.note,
        booked_time: new Date(bookedTime).toISOString(),
      });
      
      alert(response.data.message);
      navigate("/");
    } catch (error) {
      alert('Error: ' + (error.response?.data?.message || error.message));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="book-demo-container">
        <h2 className="form-title">Book a Demo With Us!</h2>
        <Formik
          initialValues={{
            fullName: '',
            email: '',
            phoneNumber: '',
            company: '',
            note: '',
            selectedDate: null,
            selectedTime: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form className="book-demo-form">
              <div className="form-grid">
                <div className="form-field">
                  <Field as={TextField} label="Full Name" name="fullName" fullWidth margin="dense" />
                  <ErrorMessage name="fullName" component="div" className="error-message" />
                </div>

                <div className="form-field">
                  <Field as={TextField} label="Email" name="email" fullWidth margin="dense" />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>

                <div className="form-field">
                  <Field as={TextField} label="Phone Number" name="phoneNumber" fullWidth margin="dense" />
                  <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                </div>

                <div className="form-field">
                  <Field as={TextField} label="Company" name="company" fullWidth margin="dense" />
                  <ErrorMessage name="company" component="div" className="error-message" />
                </div>

                <div className="form-field full-width">
                  <Field as={TextField} label="Note" name="note" fullWidth margin="dense" multiline rows={1} />
                </div>
            

                <div className="form-field">
                  <label>Select Date</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setFieldValue('selectedDate', date);
                    }}
                    dateFormat="MMMM d, yyyy"
                    minDate={new Date()}
                    filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
                    placeholderText="Select a date"
                    className="custom-datepicker"
                  />
                  <ErrorMessage name="selectedDate" component="div" className="error-message" />
                </div>

                <div className="form-field">
                  <label>Select Time</label>
                  <DatePicker
                    selected={selectedTime}
                    onChange={(time) => {
                      setSelectedTime(time);
                      setFieldValue('selectedTime', time);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    minTime={new Date().setHours(9, 0)}
                    maxTime={new Date().setHours(17, 0)}
                    placeholderText="Select a time"
                    className="custom-timepicker"
                  />
                  <ErrorMessage name="selectedTime" component="div" className="error-message" />
                </div>
              </div>
              <Button type="submit" color="primary" variant="contained" style={{ marginTop: '20px' }} disabled={isSubmitting}>
                Book
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </>
  );
};

export default BookDemo;


// import React, { useState } from 'react';
// import { TextField, Button } from '@mui/material';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import './BookDemo.css';

// const BookDemo = () => {
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(null);
//   const navigate = useNavigate();

//   // Define validation schema using Yup
//   const validationSchema = Yup.object().shape({
//     fullName: Yup.string().required('Full Name is required'),
//     email: Yup.string().email('Invalid email format').required('Email is required'),
//     company: Yup.string().required('Company is required'),
//     note: Yup.string(),
//     selectedDate: Yup.date().nullable().required('Date is required'),
//     selectedTime: Yup.date().nullable().required('Time is required'),
//   });

//   const handleSubmit = async (values, { setSubmitting }) => {
//     setSubmitting(true);
//     try {
//       const bookedTime = values.selectedDate && values.selectedTime
//         ? new Date(values.selectedDate).setHours(values.selectedTime.getHours(), values.selectedTime.getMinutes())
//         : '';

//       const response = await axios.post('http://127.0.0.1:5000//book-demo', {
//         full_name: values.fullName,
//         email: values.email,
//         company: values.company,
//         note: values.note,
//         booked_time: new Date(bookedTime).toISOString(),
//       });

//       alert(response.data.message);
//       navigate("/");
//     } catch (error) {
//       alert('Error: ' + error.response?.data?.message || error.message);
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="book-demo-container">
//         <h2 className="form-title">Book a Demo With Us!</h2>
//         <Formik
//           initialValues={{
//             fullName: '',
//             email: '',
//             company: '',
//             note: '',
//             selectedDate: null,
//             selectedTime: null,
//           }}
//           validationSchema={validationSchema}
//           onSubmit={handleSubmit}
//         >
//           {({ setFieldValue, isSubmitting }) => (
//             <Form className="book-demo-form">
//               <div className="form-grid">
//                 <div className="form-field">
//                   <Field as={TextField} label="Full Name" name="fullName" fullWidth margin="dense" />
//                   <ErrorMessage name="fullName" component="div" className="error-message" />
//                 </div>

//                 <div className="form-field">
//                   <Field as={TextField} label="Email" name="email" fullWidth margin="dense" />
//                   <ErrorMessage name="email" component="div" className="error-message" />
//                 </div>
//                 <div className="form-field">
//                   <Field as={TextField} label="Email" name="email" fullWidth margin="dense" />
//                   <ErrorMessage name="email" component="div" className="error-message" />
//                 </div>

//                 <div className="form-field">
//                   <Field as={TextField} label="Company" name="company" fullWidth margin="dense" />
//                   <ErrorMessage name="company" component="div" className="error-message" />
//                 </div>

//                 <div className="form-field">
//                   <Field as={TextField} label="Note" name="note" fullWidth margin="dense" />
//                 </div>

//                 <div className="form-field">
//                   <label>Select Date</label>
//                   <DatePicker
//                     selected={selectedDate}
//                     onChange={(date) => {
//                       setSelectedDate(date);
//                       setFieldValue('selectedDate', date);
//                     }}
//                     dateFormat="MMMM d, yyyy"
//                     minDate={new Date()}
//                     filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
//                     placeholderText="Select a date"
//                     className="custom-datepicker"
//                   />
//                   <ErrorMessage name="selectedDate" component="div" className="error-message" />
//                 </div>

//                 <div className="form-field">
//                   <label>Select Time</label>
//                   <DatePicker
//                     selected={selectedTime}
//                     onChange={(time) => {
//                       setSelectedTime(time);
//                       setFieldValue('selectedTime', time);
//                     }}
//                     showTimeSelect
//                     showTimeSelectOnly
//                     timeIntervals={30}
//                     timeCaption="Time"
//                     dateFormat="h:mm aa"
//                     minTime={new Date().setHours(9, 0)}
//                     maxTime={new Date().setHours(17, 0)}
//                     placeholderText="Select a time"
//                     className="custom-timepicker"
//                   />
//                   <ErrorMessage name="selectedTime" component="div" className="error-message" />
//                 </div>
//               </div>
//               <Button type="submit" color="primary" variant="contained" style={{ marginTop: '20px' }} disabled={isSubmitting}>
//                 Book
//               </Button>
//             </Form>
//           )}
//         </Formik>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default BookDemo;

// import React, { useState } from 'react';
// import { TextField, Button } from '@mui/material';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import './BookDemo.css';

// const BookDemo = () => {
//   const [fullName, setFullName] = useState('');
//   const [email, setEmail] = useState('');
//   const [company, setCompany] = useState('');
//   const [note, setNote] = useState('');
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(null);
//   const navigate = useNavigate();

//   const handleSubmit = async () => {
//     try {
//       const bookedTime = selectedDate && selectedTime
//         ? new Date(selectedDate).setHours(selectedTime.getHours(), selectedTime.getMinutes())
//         : '';
//       const response = await axios.post('http://127.0.0.1:5000//book-demo', {
//         full_name: fullName, email, company, note,
//         booked_time: new Date(bookedTime).toISOString(),
//       });
//       alert(response.data.message);
//       navigate("/");
//     } catch (error) {
//       alert('Error: ' + error.response?.data?.message || error.message);
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="book-demo-container">
//         <div>
//         <h2 className="form-title">Book a Demo With Us!</h2>

//         </div>
//         <form className="book-demo-form">
//           <div className="form-grid">
//             <div className="form-field"><TextField label="Full Name" fullWidth value={fullName} onChange={(e) => setFullName(e.target.value)} margin="dense" required /></div>
//             <div className="form-field"><TextField label="Email" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} margin="dense" required /></div>
//             <div className="form-field"><TextField label="Company" fullWidth value={company} onChange={(e) => setCompany(e.target.value)} margin="dense" required  /></div>
//             <div className="form-field"><TextField label="Note" fullWidth value={note} onChange={(e) => setNote(e.target.value)} margin="dense" /></div>
//             <div className="form-field"><label>Select Date</label><DatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} dateFormat="MMMM d, yyyy" minDate={new Date()} filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6} placeholderText="Select a date" className="custom-datepicker" /></div>
//             <div className="form-field"><label>Select Time</label><DatePicker selected={selectedTime} onChange={(time) => setSelectedTime(time)} showTimeSelect showTimeSelectOnly timeIntervals={30} timeCaption="Time" dateFormat="h:mm aa" minTime={new Date().setHours(9, 0)} maxTime={new Date().setHours(17, 0)} placeholderText="Select a time" className="custom-timepicker" /></div>
//           </div>
//           <Button onClick={handleSubmit} color="primary" variant="contained" style={{ marginTop: '20px' }}>Book</Button>
//         </form>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default BookDemo;

// import React, { useState } from 'react';
// import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// const BookDemo = () => {
//   const [fullName, setFullName] = useState('');
//   const [email, setEmail] = useState('');
//   const [company, setCompany] = useState('');
//   const [note, setNote] = useState('');
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(null);
//   const navigate = useNavigate();

//   const handleSubmit = async () => {
//     try {
//       const bookedTime = selectedDate && selectedTime
//         ? new Date(selectedDate).setHours(selectedTime.getHours(), selectedTime.getMinutes())
//         : '';

//       const response = await axios.post('http://127.0.0.1:5000//book-demo', {
//         full_name: fullName,
//         email: email,
//         company: company,
//         note: note,
//         booked_time: new Date(bookedTime).toISOString(),
//       });

//       alert(response.data.message);
//       window.location.href = "http://carren.cc:3000";
//     } catch (error) {
//       alert('Error: ' + error.response?.data?.message || error.message);
//     }
//   };

//   return (
//     <>
//     <Navbar />
//     <Dialog open={true}>
//       <DialogTitle>Book a Demo</DialogTitle>
//       <DialogContent>
//         <TextField
//           label="Full Name"
//           fullWidth
//           value={fullName}
//           onChange={(e) => setFullName(e.target.value)}
//           margin="dense"
//         />
//         <TextField
//           label="Email"
//           fullWidth
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           margin="dense"
//         />
//         <TextField
//           label="Company"
//           fullWidth
//           value={company}
//           onChange={(e) => setCompany(e.target.value)}
//           margin="dense"
//         />
//         <TextField
//           label="Note"
//           fullWidth
//           value={note}
//           onChange={(e) => setNote(e.target.value)}
//           margin="dense"
//         />

//         {/* Date Picker for Date Selection */}
//         <div style={{ marginTop: '16px' }}>
//           <label>Select Date</label>
//           <DatePicker
//             selected={selectedDate}
//             onChange={(date) => setSelectedDate(date)}
//             dateFormat="MMMM d, yyyy"
//             minDate={new Date()} // Disable past dates
//             filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6} // Disable weekends
//             placeholderText="Select a date"
//             className="custom-datepicker"
//           />
//         </div>

//         {/* Time Picker for Time Selection */}
//         <div style={{ marginTop: '16px' }}>
//           <label>Select Time</label>
//           <DatePicker
//             selected={selectedTime}
//             onChange={(time) => setSelectedTime(time)}
//             showTimeSelect
//             showTimeSelectOnly
//             timeIntervals={30}
//             timeCaption="Time"
//             dateFormat="h:mm aa"
//             minTime={new Date().setHours(9, 0)}  // Set 9:00 AM as the minimum time
//             maxTime={new Date().setHours(17, 0)}  // Set 5:00 PM as the maximum time
//             placeholderText="Select a time"
//             className="custom-timepicker"
//           />
//         </div>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleSubmit} color="primary" variant="contained">
//           Book
//         </Button>
//       </DialogActions>
//     </Dialog>

//     </>
//   );
// };

// export default BookDemo;
