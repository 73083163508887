// // MainLayout.js
// import React from "react";
// import Home from "./Home";
// import About from "./About";
// import FAQ from "./faq";
// import Highlights from "./highlights";
// import Work from "./Work";
// import TransformDecisions from "./TransformDecisions";
// import Contact from "./Contact";
// import Footer from "./Footer";

// const MainLayout = () => {
//   return (
//     <div className="App">
//       {/* <Navbar /> */}
//       <Home />
//       <About />
//       <Work />
//       <TransformDecisions />
//       <Highlights />
//       <FAQ />
//       <Contact />
//       <Footer />
//     </div>
//   );
// };

// export default MainLayout;


import React, { useRef } from "react";
import Home from "./Home";
import About from "./About";
import FAQ from "./faq";
import Highlights from "./highlights";
import Work from "./Work";
import TransformDecisions from "./TransformDecisions";
import Contact from "./Contact";
import Footer from "./Footer";

const MainLayout = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const workRef = useRef(null);
  const transformDecisionsRef = useRef(null);
  const highlightsRef = useRef(null);
  const faqRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div className="App">
      <Home ref={homeRef} />
      <Work ref={workRef} />
      <TransformDecisions ref={transformDecisionsRef} />
      <Highlights ref={highlightsRef} />
      <Footer />
    </div>
  );
};

export default MainLayout;
